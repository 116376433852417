<template>
  <el-dialog v-model="dialogVisible" title="編輯跑馬燈" width="650px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="content-form"
    >
      <el-form-item class="form-item">
        <template #label>
          <span class="label">門市</span>
        </template>
        <div class="name-wrap">
          <p>{{ ruleForm.store_name }}</p>
        </div>
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">
            內容
            <span class="tips">上限20字</span>
          </span>
        </template>
        <el-input v-model="ruleForm.value" placeholder="請輸入內容" maxlength="20" show-word-limit />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="saveValue(ruleFormRef)"
          :disabled="!ruleForm.value || ruleForm.value === cusProps.init.value"
          style="margin-left: 25px"
        >
          儲存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref } from 'vue';
import { useActions } from '@/utils/mapStore';
export default {
  name: 'marquee-dialog',
  setup() {
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //設定初始值
    if (dialogVisible) {
      ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
    }

    //送出
    const doSaveMarquee = useActions('marquee', ['doSaveMarquee']);
    const saveValue = async (form) => {
      try {
        if (!form) return;
        await doSaveMarquee({ shop_id: ruleForm.value.store_id, value: ruleForm.value.value });
        submit({ value: ruleForm.value.value, index: cusProps.index });
      } catch (e) {
        e;
      }
    };
    return {
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      saveValue,
    };
  },
};
</script>
<style lang="scss" scoped>
.content-form {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  grid-column-gap: 25px;
}
.name-wrap {
  width: 100%;
  height: 32px;
  padding: 0 11px;
  border: 1px solid $el-border-color-lighter;
  border-radius: 4px;
  font-size: 15px;
  color: $color-line;
  p {
    line-height: 30px;
  }
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
